<template>
  <div class="main">
    <el-carousel indicator-position="outside">
      <el-carousel-item 
		v-for="(item,index) in list" 
		:key="index">
        <h1>{{ item.title }}</h1>
		<h2>{{ item.name }}</h2>
		<h3>{{ item.span }}</h3>
        <el-image :src="item.img"></el-image>
		<img class="btnImg" :src="item.btn" alt="" @click="toYJT">
      </el-carousel-item>
    </el-carousel>
    <div>
      <div class="FFFback">
			<div class="qyhxys" style="height:680px;">
				<div class="content">
					<h1>核心优势</h1>
					<img
						class="headLogo"
						src="../../assets/image/index/pexels-samuel.png"
					/>
					</div>
					<div class="bottom">
						<div class="botItem">
							<img src="../../assets/image/index/joinus.png" />
							<p>自研设备</p>
							<div class="text">响应政府号召，深入研究前沿技术，致力于科技创新</div>
							<div class="moreA" @click="toModel('/yjtsy','1')">了解详情</div>
						</div>
						<div class="botItem">
							<img src="../../assets/image/index/missage.png" />
							<p>专业方案</p>
							<div class="text">投身行业内部，探索本质业务需求，助力企业数字化转型</div>
							<div class="moreA" @click="toModel('/zhmt','1')">了解详情</div>
						</div>
						<div class="botItem">
							<img src="../../assets/image/index/notice.png" />
							<p>优质服务</p>
							<div class="text">提供专业的售后服务，保证业务系统的稳定性和良好的用户体验</div>
							<div class="moreA" @click="toModel('/aftersale','3')">了解详情</div>
						</div>
					</div>
				</div>
			</div>
            <div class="yj-news qyhxys">
				<div class="hyzx">
					<h1>新闻资讯</h1>
					<el-carousel direction="vertical" :interval="3000">
						<el-carousel-item 
							v-for="(item,index) in newsList" 
							:key="index"
						>
							<div class="newsCon" @click="toDetail(item.id)">
								<div class="left news-pic">
									<img :src="item.pic" alt=""/>
								</div>
								<div class="right">
									<h6>{{item.title}}</h6>
									<p class="news-desc">{{item.illustrate}}</p>
									<div class="bottomMore">
										<div class="newsDate">发布日期：{{ $moment(item.createTime).format('YYYY-MM-DD') }}</div>
										<div class="seeMore" @click="seeMore">查看更多 > </div>
									</div>
								</div>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
			<div class="yjback">
				<div class="qyhxys guanyuyujiang">
					<div>
					<h1>关于禹江</h1>
					<div>
						<div class="port-7 effect-3">
							<div class="image-box">
								<img src="../../assets/image/index/pexels-jeshootscom-442580.png">
							</div>
							<div class="text-desc">
								<h3>公司介绍</h3>
								<p>智慧助力航运</p>
								<p>合作多联共赢</p>
								<div class="moreA" @click="toModel('/introduce','4')">了解详情</div>
							</div>
						</div>
						<div class="port-7 effect-3">
							<div class="image-box">
								<img src="../../assets/image/index/IMG_4961.png">
							</div>
							<div class="text-desc">
								<h3>加入我们</h3>
								<p>创新、实践、奉献、共赢</p>
								<p>以责任为基、以市场为需</p>
								<div class="moreA" @click="toModel('/joinus','4')">了解详情</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    </div>
  </div>
</template>
<script>
import { owJournalism } from "@/api/api";
export default {
  name: "index",
  data(){
    return {
		list: [
			{
				img: require("@/assets/image/index/003.png"),
				name: "一江通",
				span: "智慧物流服务平台",
				btn: require("@/assets/image/index/btn.png")
			},
			{
				img: require("@/assets/image/index/001.png"),
				title: "智慧助力航运 合作多联共赢"
			},
			{
				img: require("@/assets/image/index/002.png"),
				title: "以责任为基 以市场为需"
			}
		],
		isLogo: false,
	    newsList: [],
		queryParams: {
			currentPage: 1,
			pageSize: 10,
		},
    };
  },
  mounted() {
	this.getList();
  },
  methods: {
	getList(){
      let params = this.queryParams
      owJournalism(params).then((res) => {
        if (res.success == true) {
          this.newsList = res.data.records;
        }
      });
    },
	toYJT(){
		window.open("http://yjzhwh.com", '_blank');
	},
	toModel(path,activeIndex){
		this.$router.push({
          path: path,
		  query: {activeIndex:activeIndex}
        })
	},
	seeMore(event){
		event.stopPropagation();
		this.$router.push({
          path: "/industryNews",
          query: { activeIndex:"2"}
        })
	},
	toDetail(id){
		this.$router.push({
			path:"/detail",
			query: { id: id, activeIndex:"2"}
		})
    },
  },
};
</script>
<style scoped lang="scss">
.main {
  display: block;
  flex: 1;
}
::v-deep .el-carousel__container{
  height: 34.75rem;
  width: 100%;
  h1{
	position: absolute;
    top: 12%;
	width: 100%;
	text-align: center;
    color: #FFFFFF;
    font-size: 3.2rem;
    letter-spacing: 1rem;
	z-index: 9999;
  }
  h2{
	position: absolute;
    top: 20%;
    left: 20%;
    color: #FFFFFF;
    font-size: 3.5rem;
    letter-spacing: 1rem;
	z-index: 9999;
	animation: dragTop .7s ease-in-out both;
  }
  h3{
	position: absolute;
    top: 45%;
    left: 20%;
    color: #FFFFFF;
    font-size: 3.125rem;
    letter-spacing: 1rem;
	z-index: 9999;
  }
  .el-image{
	height: 100%;
	width: 100%;
  }
  .btnImg{
	position: absolute;
    top: 70%;
    left: 20%;
	z-index: 9999;
	cursor: pointer;
  }
}
::v-deep .el-carousel__indicators--outside{
  bottom: 0.6rem;
  position: absolute;
}
.hyzx{
	.el-carousel--vertical{
		max-height: 400px;
	}
}
</style>
